<template>
  <div class="content">
    <div class="member-search">
      <Row>
        <Col span="24">
          <h2 class="member-search-title border-bottom">
            <span class="member-search-text">按条件搜索</span>
          </h2>
        </Col>
      </Row>
      <div class="search-form" @keyup.enter="handleSearch()">
        <Form :label-width="112" :model="searchData" ref="formValidate">
          <Row>
            <Col span="5">
              <Form-item label="姓名：">
                <Input v-model="searchData.name" placeholder="请输入..."></Input>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="性别：">
                <Select clearable v-model="gender" placeholder="请选择" class="w80">
                  <Option value="0">男</Option>
                  <Option value="1">女</Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="联系电话：">
                <Input v-model="searchData.phone" placeholder="请输入..."></Input>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="6">
              <Form-item label="身份证号：">
                <Input v-model="searchData.id_card" placeholder="请输入..."></Input>
              </Form-item>
            </Col>
          </Row>

          <Row>
            <Col span="11">
              <Form-item label="顾客类型：">
                <Row>
                  <Col span="10">
                    <Select
                      clearable
                      v-model="searchData.member_type1"
                      placeholder="请选择"
                      @on-change="changeType"
                    >
                      <Option :value="v.id" v-for="v in typeList" :key="v.id">{{ v.name }}</Option>
                    </Select>
                  </Col>
                  <Col span="1">&nbsp;</Col>
                  <Col span="13">
                    <Select
                      clearable
                      multiple
                      v-model="type_item_ids"
                      placeholder="请选择"
                      @on-change="changeTypeItem"
                      @click.native="changeItems"
                    >
                      <Option :value="v.id" v-for="v in typeItemList" :key="v.id">{{ v.name }}</Option>
                    </Select>
                  </Col>
                </Row>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="促进日期：">
                <Date-picker
                  v-model="time"
                  @on-change="handleChangeDate"
                  type="date"
                  placeholder="选择日期"
                ></Date-picker>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="状态：">
                <Select clearable v-model="searchData.status" placeholder="请选择">
                  <Option :value="v.value" v-for="v in statusList" :key="v.value">{{ v.label }}</Option>
                </Select>
              </Form-item>
            </Col>
          </Row>

          <Row v-if="!branchShow">
            <Col span="5">
              <Form-item label="省份：">
                <Select clearable v-model="searchData.pid" placeholder="请选择" @on-change="changePro">
                  <Option :value="v.id" v-for="v in proList" :key="v.id">
                    {{
                      v.name
                    }}
                  </Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="城市：">
                <Select
                  clearable
                  v-model="searchData.cid"
                  placeholder="请选择"
                  @on-change="changeCity"
                  @click.native="changeBossCity"
                >
                  <Option :value="v.id" v-for="v in cityList" :key="v.id">
                    {{
                      v.name
                    }}
                  </Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="门店：">
                <Select
                  clearable
                  v-model="searchData.sid"
                  placeholder="请选择"
                  @on-change="changeBranch"
                  @click.native="changeBossBranch"
                >
                  <Option :value="v.id" v-for="v in storeList" :key="v.id">
                    {{
                      v.name
                    }}
                  </Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="6">
              <Form-item label="服务员工：">
                <Select
                  clearable
                  placeholder="请选择"
                  v-model="searchData.belong_staff_id"
                  @click.native="handleChangeStaff"
                >
                  <Option
                    v-for="item in staffFormData"
                    :value="item.id"
                    :key="item.id"
                  >{{ item.name }}</Option>
                </Select>
              </Form-item>
            </Col>
          </Row>
          <!-- 员工 -->
          <Row v-else>
            <Col span="5">
              <Form-item label="省份：">
                <Input disabled v-model="proName"></Input>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="城市：">
                <Input disabled v-model="cityName"></Input>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="门店：">
                <Input disabled v-model="sName"></Input>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="6">
              <Form-item label="服务员工：">
                <Select
                  clearable
                  placeholder="请选择"
                  v-model="searchData.belong_staff_id"
                  @click.native="handleChangeStaff"
                >
                  <Option
                    v-for="item in staffFormData"
                    :value="item.id"
                    :key="item.id"
                  >{{ item.name }}</Option>
                </Select>
              </Form-item>
            </Col>
          </Row>

          <Row>
            <Col span="5">
              <Form-item label="服务部门：">
                <Select
                  clearable
                  v-model="searchData.bm_id"
                  placeholder="请选择"
                  @on-change="changeServiceDep"
                >
                  <Option :value="v.value" v-for="v in serviceDepList" :key="v.value">{{ v.label }}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="服务客服：">
                <Select
                  clearable
                  placeholder="请选择"
                  v-model="searchData.fw_staff_id"
                  @click.native="handleChangeServiceCustomer"
                >
                  <Option
                    v-for="item in serviceStaffList"
                    :value="item.id"
                    :key="item.id"
                  >{{ item.name }}</Option>
                </Select>
              </Form-item>
            </Col>
          </Row>

          <Row>
            <Col :lg="{ span: 12, offset: 12 }" :md="{ span: 12, offset: 12 }">
              <Button type="warning" @click.native="handleSearch">&nbsp;&nbsp;搜索&nbsp;&nbsp;</Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
    <div class="content-box">
      <h2 class="content-title border-bottom">服务汇总</h2>
      <div class="content-body">
        <Table :columns="columns" :data="resultData" ref="table"></Table>
        <Row>
          <Col span="12">
            <div class="table-oprate"></div>
          </Col>
          <Col span="12">
            <span class="records">共{{ pageTotal }}条记录</span>
          </Col>
        </Row>
        <div class="page-nations-wrapper">
          <div class="page-nations">
            <Page
              :total="pageTotal"
              :current="searchData.page"
              :page-size="searchData.size"
              @on-change="handleChangePage"
            ></Page>
          </div>
        </div>
      </div>
    </div>
    <Modal
      :title="modalData.rq + ' 服务记录'"
      v-model="showModal"
      class-name="vertical-center-modal"
      width="900"
    >
      <Row>
        <Col span="14">
          <div class="card">
            <div class="card-title">服务记录</div>
            <div class="card-body">
              <div class="card-content">{{ modalData.nr }}</div>
            </div>
          </div>
        </Col>
        <Col span="10">
          <div class="card">
            <div class="card-title">会员问题记录</div>
            <div class="card-body">
              <div class="card-content">{{ modalData.wt }}</div>
            </div>
          </div>
        </Col>
      </Row>
      <Row class="modal-card">
        <Col span="24">
          <div class="card">
            <div class="card-title">解决方案</div>
            <div
              class="card-title card-title_status"
              :style="{ color: modalData.zt === '0' ? 'red' : '#13ce66' }"
            >{{ modalData.zt === '0' ? '未解决' : '已解决' }}</div>
            <div class="card-body">
              <div class="card-content" v-if="modalData.jjfa">{{ modalData.jjfa }}</div>
              <Input v-model="solution" type="textarea" :rows="9" placeholder="请输入解决方案" v-else></Input>
            </div>
          </div>
        </Col>
      </Row>
      <div style="margin-left: 20px">
        提交日期：
        <Date-picker
          v-model="tjsj"
          type="date"
          @on-change="handleSubmitDate"
          placeholder="选择日期"
          :disabled="modalData.jjfa"
        ></Date-picker>
      </div>
      <div slot="footer" style="text-align: center">
        <Button
          type="warning"
          @click.native="handleSubmit"
          v-if="!modalData.jjfa"
        >&nbsp;&nbsp;提交&nbsp;&nbsp;</Button>
      </div>
    </Modal>
  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
import ServiceRecordService from '@/services/serviceRecordService';

export default {
	data() {
		return {
			solution: '',
			showModal: false,
			modalData: {},
			searchData: {
				name: '',
				gender: '',
				phone: '',
				id_card: '',
				member_type1: '',
				member_type2: [],
				pid: '',
				cid: '',
				sid: '',
				belong_staff_id: '',
				cjrq: '',
				bm_id: '',
				fw_staff_id: '',
				zt: '',
				page: 1,
				size: 10,
			},
			type_item_ids: [],
			typeList: [],
			typeItemList: [],
			typeItem: {},
			proList: [],
			cityList: [],
			city: {},
			store: {},
			storeList: [],
			new_staff_list: {},
			staffFormData: [],
			maritalType: [],
			jobType: [],
			gradeType: [],
			nativePlaceList: [],
			native_place: [],
			nativeData: [],
			densityGradeList: [],
			density_grade: [],
			densityData: [],
			branchData: [],
			branch: [],
			member_type_Array: [],
			time: '',
			gender: '',
			ABO_type: '',
			columns: [
				{ title: '日期', key: 'rq', align: 'center' },
				{ title: '姓名', key: 'xm', align: 'center' },
				{ title: '门店', key: 'branch', align: 'center' },
				{ title: '手机号', key: 'phone', align: 'center' },
				{ title: '内容', key: 'nr', align: 'center' },
				{ title: '问题', key: 'wt', align: 'center' },
				{ title: '服务部门', key: 'bm', align: 'center' },
				{ title: '服务客服', key: 'staff', align: 'center' },
				{
					title: '状态',
					key: 'zt',
					align: 'center',
					render: (h, params) => {
						return h(
							'span',
							{
								style: {
									color: params.row.zt === '0' ? 'red' : '#13ce66',
								},
							},
							params.row.zt === '0' ? '未解决' : '已解决',
						);
					},
				},
				{
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					render: (h, params) => {
						return h('div', [
							h(
								'a',
								{
									style: {
										marginRight: 0,
										color: '#ff8400',
									},
									on: {
										click: () => {
											ServiceRecordService.getServiceDetail(params.row.id).then((data) => {
												this.modalData = data;
												this.tjsj = data.tjsj;
											});
											this.showModal = true;
										},
									},
								},
								'查看',
							),
						]);
					},
				},
			],
			resultData: [],
			pageTotal: 0,
			branchShow: false,
			branch_id: [],
			power: [],
			choConhabit: false,
			proName: '',
			cityName: '',
			sName: '',
			statusList: [
				{
					value: '1',
					label: '已解决',
				},
				{
					value: '0',
					label: '未解决',
				},
			],
			serviceDepList: [
				{
					value: '11',
					label: '服务管理',
				},
				{
					value: '8',
					label: '方案部',
				},
				{
					value: '15',
					label: '教练部',
				},
				{
					value: '10',
					label: '专家部',
				},
				{
					value: '9',
					label: '促进部',
				},
			],
			serviceStaffList: [],
			tjsj: '',
		};
	},
	watch: {
		// 如果 branchArr 发生改变，这个函数就会运行
		member_type_Array() {
			this.searchData.member_type_id = this.member_type_Array[1];
		},
	},
	methods: {
		// 顾客类型
		changeType() {
			this.typeItemList = this.typeItem[this.searchData.member_type1];
			this.type_item_ids = [];
		},
		changeTypeItem(value) {
			this.searchData.member_type2 = JSON.stringify(value);
		},
		changeItems() {
			if (!this.searchData.member_type1) {
				this.$Message.warning('请先选择顾客类型');
			}
		},
		// 根据省份选择城市
		changePro() {
			this.cityList = this.city[this.searchData.pid];
			this.searchData.cid = '';
		},
		// 根据城市选择门店
		changeCity() {
			this.storeList = this.store[this.searchData.cid];
			this.searchData.sid = '';
		},
		// 根据门店选择员工
		changeBranch() {
			this.staffFormData = this.new_staff_list[this.searchData.sid];
		},
		changeServiceDep(value) {
			ServiceRecordService.getDepStaff(value).then((data) => {
				this.serviceStaffList = data;
			});
		},
		initData() {
			let list = JSON.parse(localStorage.getItem('userInfo'));
			MemberService.getBase().then((data) => {
				this.maritalType = data.marital;
				this.jobType = data.p_job;
				// this.gradeType = data.grade
				data.grade.forEach((item) => {
					this.gradeType.unshift(item);
				});
				this.densityGradeList = data.density;
				this.branchData = data.branch;
				this.proList = data.new_staff_list.pro;
				this.city = data.new_staff_list.city;
				this.store = data.new_staff_list.store;
				this.new_staff_list = data.new_staff_list.staff;
				this.typeList = data.member_type.list_type1;
				this.typeItem = data.member_type.list_type2;
				this.power = list.user_info.power;
				this.memberTypeData = data.member_type;
				if (this.power.indexOf('服务管理--查看所属店面会员信息权限') === -1) {
					this.branchShow = false;
				} else {
					if (
						this.power.indexOf('服务管理--查看全国会员信息权限') === -1 &&
						this.power.indexOf('服务管理--查看所属区域会员信息权限') === -1
					) {
						this.branchShow = true;
						this.branch_id = list.user_info.branch;
						// this.staffValue = this.branch_id[2]
						this.searchData.sid = this.branch_id[2];
						this.searchData.cid = this.branch_id[1];
						this.searchData.pid = this.branch_id[0];
						this.proName = list.user_info.pro_name;
						this.cityName = list.user_info.city_name;
						this.sName = list.user_info.branch_name;
						this.changeBranch();
					}
					this.getQuery();
				}
			});
		},
		// 会员信息列表
		getQuery() {
			this.searchData.gender = this.gender;
			ServiceRecordService.getServiceList(this.searchData).then((data) => {
				this.resultData = data.list;
				this.pageTotal = data.row_size;
			});
		},
		// 搜索会员信息
		handleSearch() {
			this.searchData.page = 1;
			this.getQuery();
		},
		// 促进日期
		handleChangeDate(date) {
			this.searchData.cjrq = date;
		},
		// 页码
		handleChangePage(page) {
			this.searchData.page = page;
			this.getQuery();
		},
		// 点击服务员工先选择门店
		handleChangeStaff() {
			if (!this.searchData.sid) {
				this.$Message.warning('请先选择门店');
			}
		},
		// 点击服务客服先选择服务部门
		handleChangeServiceCustomer() {
			if (!this.searchData.bm_id) {
				this.$Message.warning('请先选择服务部门');
			}
		},
		// 点击门店先选择城市
		changeBossBranch() {
			if (!this.searchData.cid) {
				this.$Message.warning('请先选择城市');
			}
		},
		// 点击城市先选择省份
		changeBossCity() {
			if (!this.searchData.pid) {
				this.$Message.warning('请先选择省份');
			}
		},
		// 提交服务记录解决办法
		handleSubmit() {
			if (!this.solution) {
				this.$Message.warning('请填写解决方案');
				return;
			}
			if (!this.tjsj) {
				this.$Message.warning('请填写提交日期');
				return;
			}
			const params = {
				jl_id: this.modalData.id,
				jjfa: this.solution,
				tjsj: this.tjsj,
			};
			ServiceRecordService.submitServiceSolution(params)
				.then(() => {
					this.$Message.success('提交成功');
					this.getQuery();
					this.showModal = false;
				})
				.catch(() => {
					this.$Message.warning('提交失败，请重试');
				});
		},
		handleSubmitDate(date) {
			this.tjsj = date;
		},
	},
	mounted() {
		this.initData();
		this.getQuery();
	},
};
</script>
<style lang="less" scoped>
.service-manage-container {
  .member-search {
    background-color: #fff;
    margin-bottom: 20px;
  }
}
.search-form {
  padding: 20px 15px 30px 15px;
}
.search-form .ivu-input-number {
  width: 100%;
}
.member-list .member-title {
  margin-bottom: 16px;
}
.member-list .member-oprate {
  float: right;
  padding-right: 8px;
}
.card {
  margin: 0 20px 20px;
  &-title {
    background-color: #507fe0;
    font-size: 16px;
    color: #fff;
    padding: 5px 10px;
    display: inline-block;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    &_status {
      background-color: #fff;
      border: 1px #e9e9e9 solid;
      border-bottom: none;
    }
  }
  &-body {
    height: 220px;
    border: 1px #e9e9e9 solid;
    padding: 10px;
  }
  &-select {
    width: 50%;
  }
  &-textarea_left {
    margin-top: 10px;
  }
}
</style>
